
.container {
    flex: 0.5;
    flex-basis: 500px;

    min-width: 0;
    min-height: 0;
    
    width: 100%;
    height: 100%;

    max-width: 500px;
    
    object-fit: contain;

    z-index: 2;
}
