
/* Dimensions */
.widthFull { width: 100%; }
.widthLarge { width: 100vw; max-width: 80rem; }

.heightFull { height: 100%; }

/* Size */
.fontSmall { font-size: 0.8rem; }
.fontMedium { font-size: 1rem; }
.fontLarge { font-size: 1.2rem; }
.font1XLarge { font-size: 1.8rem; }
.font2XLarge { font-size: 2rem; }
.font3XLarge { font-size: 3rem; }
.font4XLarge { font-size: 4rem; }
.font5XLarge { font-size: 5rem; }


/* Padding */
.paddingNone { padding: 0; }
.paddingMarginNone { padding: 0; margin: 0; }

.paddingSmall { padding: 5px; }
.paddingMedium { padding: 10px; }
.paddingLarge { padding: 20px; }


/* Margin */
.marginNone { margin: 0; }
.marginPaddingNone { padding: 0; margin: 0; }


/* Positioning */
.stickyTop { position: sticky; top: 0; }
.relative { position: relative; }


/* Flexbox */
.flex { display: flex; }
.flexRow { display: flex; flex-direction: row; }
.flexColumn { display: flex; flex-direction: column; }

.justifyCenter { justify-content: center; }
.justifySpaceBetween { justify-content: space-between; }

.alignCenter { align-items: center; }
.alignEnd { align-items: flex-end; }
.alignStart { align-items: flex-start; }

.justifyAlignCenter { justify-content: center; align-items: center; }
.alignJustifyCenter { justify-content: center; align-items: center; }

.wrap { flex-wrap: wrap; }
.wrapReverse { flex-wrap: wrap-reverse; }

.gapSmall { gap: 5px; }
.gapMedium { gap: 10px; }
.gapLarge { gap: 20px; }
.gap1XLarge { gap: 30px; }
.gap2XLarge { gap: 40px; }
.gap3XLarge { gap: 50px; }


/* Text */
.textCenter { text-align: center; }
.textLeft { text-align: left; }
.textWrap { text-wrap: wrap; }

.fontLight { font-weight: 200; }
.fontMidLight { font-weight: 300; }
.fontMedium { font-weight: 400; }
.fontMidHeavy { font-weight: 500; }
.fontHeavy { font-weight: 600; }
.font1XHeavy { font-weight: 700; }
.font2XHeavy { font-weight: 800; }
.font3XHeavy { font-weight: 900; }


/* Colors */
.backgroundPrimary { background: var(--primary-color); color: var(--background-color); }
.colorForeground { color: var(--foreground-color); }
.colorBackground { color: var(--background-color); }
.colorPrimarySvg { filter: invert(18%) sepia(84%) saturate(4141%) hue-rotate(276deg) brightness(74%) contrast(128%); }


/* Cursor */
.pointer { cursor: pointer; }


/* Animation */
.float { animation: float 8s ease-in-out infinite; }
.floatDelay { animation: float 8s ease-in-out infinite; animation-delay: 1s; }

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
