
.a {
    --underline-thickness: 2px;

    color: var(--foreground-color);
    text-decoration: none;

    background: 
        linear-gradient(to right, rgba(100, 200, 200, 0), rgba(100, 200, 200, 0)),
        linear-gradient(to right, var(--primary-color), var(--secondary-color));
    background-size: 100% var(--underline-thickness), 0 var(--underline-thickness);
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;

    transition: background-size 400ms;
}
  
.a:hover,
.a:focus {
    background-size: 0 var(--underline-thickness), 100% var(--underline-thickness);
}
