

.container {
    background: hsl(from var(--background-color) h s calc(l + 0.1));
    
    border-radius: 5px;

    padding: 5px;

    user-select: none;
}

.container p {
    padding: 0;
    margin: 0;

    color: var(--foreground-color);

    text-wrap: nowrap;
}

.container img {
    filter: invert(18%) sepia(84%) saturate(4141%) hue-rotate(276deg) brightness(74%) contrast(128%);
}
