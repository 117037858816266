

.button {
    --button-border-radius: 5px;

    border: none;

    border-bottom-right-radius: var(--button-border-radius);
    border-top-left-radius: var(--button-border-radius);
    
    -webkit-box-shadow: 0px 0px 14px 0px #00000000;
    -moz-box-shadow: 0px 0px 14px 0px #00000000;
    box-shadow: 0px 0px 14px 0px #00000000;

    transition: 300ms;
}

.button:hover {
    -webkit-box-shadow: 0px 0px 14px 0px var(--primary-color);
    -moz-box-shadow: 0px 0px 14px 0px var(--primary-color);
    box-shadow: 0px 0px 14px 0px var(--primary-color);
}
