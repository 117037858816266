
.hidden {
    display: none;
}

.logo { display: none; }
.hamburgerMenuIcon {
    background: none;
    border: none;
}
.hamburgerMenuIconClosed {
    display: block;

    background: none;
    border: none;

    height: 24px;
    width: 24px;
}
.hamburgerMenuIcon img, .hamburgerMenuIconClosed img {
    cursor: pointer;

    height: 24px;
    width: 24px;

    filter: invert();
}
.ul {
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 800px) {
    .nav {
        position: fixed;

        top: -150px;
        right: -150px;
        bottom: -150px;
        left: -150px;

        padding: 150px;

        background: var(--background-color);
    }

    .ul {
        height: 100%;
        width: 100%;

        flex-direction: column;

        justify-content: center;
        align-items: center;

        font-size: 7vw;

        gap: 1em;
    }

    .logo {
        display: block;

        margin-bottom: 2em;
    }

    /* Keeps the nav centered given the logo. Could probably be wrapped in a div instead? */
    .counterWeight {
        margin-top: 2em;
    }

    .hamburgerMenuIcon {
        display: block;

        position: fixed;

        top: 12px;
        right: 12px;
    }
}
