

.waves {
    position: relative;

    width: 100%;
    /* height: 30px; */
}
.waves::after {
    position: absolute;
    content: '';

    width: 100%;
    aspect-ratio: 960/150;

    z-index: 1;

    transform: translateY(-70%);

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    background-image: url("@images/transitions/wave.svg");
}
