
.header {
    /* Fixes an issue with a tiny gap appearing on webkit */
    transform: translateY(-5px);
    padding-top: 15px;
}


.marquee img {
    height: 100px;

    margin: 0 50px;
}

.verticalTimeline::before {
    border-radius: 2px;
}
