@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  /* The color of text */
  --foreground-color: white;
  /* The color of background items */
  --background-color: rgb(20, 20, 20);
  --background-color-secondary: #0c000e;
  --background-color-tertiary: #230029;

  /* Brand color pallet */
  --primary-color: #8b00ac;
  --secondary-color: #8300c7;
  --tertiary-color: rgb(93, 0, 143);
  --quaternary-color: rgb(67, 0, 104);
  --quinary-color: rgb(50, 0, 77);
  
  
  --primary-color-bright: #d101ec;
  --secondary-color-bright: #8600ce;
}


* {
  box-sizing: border-box;

  font-family: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--background-color);

  font-size: calc(min(1em, 4vw));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
