
.container {
    width: fit-content;

    background: var(--background-color-tertiary);

    border-radius: 5px;

    gap: 0;
}

.textAside {
    flex-basis: 15em;
    flex: 1;
    
    margin: 10px;
}

.form input, .form textarea {
    margin: 10px;
    padding: 15px;

    flex: 1;

    color: var(--foreground-color);
    background: var(--background-color);

    border: 2px solid var(--background-color-tertiary);
    border-radius: 5px;

    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.5);
}
.form input::placeholder, .form textarea::placeholder {
    color: #ccc;

    font-weight: 500;
}

.form textarea {
    min-height: 5em;
    height: auto;
    max-height: 15em;

    resize: none;
}

.submitButton {
    width: 10em;
    margin: 10px;
}
